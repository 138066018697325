body {
  margin: 0px;
  padding: 0px;
  padding-top: 60px;
  background-color: #f3efe2;
  font-family: josefin-sans, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #502B3E
}
ul {
  padding: 0;
}

/* .text-block */
.text-block {
  margin: auto;
  width: 1000px;
  max-width: 90%;
  padding: 10px;
}

/* .banner-image */
.banner-image {
  max-width: 100%;
}
.banner-image img{
  display: block;
  padding: 5px;
  max-height: 400px;
  margin-left: auto;
  margin-right: auto;
  max-width: 90%;
}

/* .class-images */
.class-images {
  width: 100%;
  display: flex;
}
.class-images img {
  flex: 33.33%;
  padding: 5px;
  width: 30%;
  max-height: 300px;
  object-fit: cover;
}

/* .student-work-item */
@media all and (max-width: 799px) {
  .student-work-item {
    display: block;
    width: 100%;
  }
}
@media all and (min-width: 800px) {
  .student-work-item {
    display: block;
    height: 300px;
    width: 100%;
  }
}
