.artwork-page-container {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: fixed;
}
.artwork-page-container img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
.artwork-page-container button {
    all: unset;
    z-index: 3;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px 20px;
    cursor: pointer;
    font-size: 48px;
}
#previous {
    left: 10px;

}
  
#next {
    right: 10px;

  }



